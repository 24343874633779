<template>
	<div class="performance performance--overview">
		<div class="performance__col">
			<number-bars class="number-bars--teal number-bars--full-width" :title="`Transactions Processed`" unit="( in millions )" :numbers="transactionsProcessed" />
		</div>
	</div>
</template>

<script>
import NumberBars from '@/components/NumberBars'

export default {
	name: 'InstitutionalTrade',
	components: {
		NumberBars,
	},
	data: () => ({
		transactionsProcessed: [
			{
				value: 795,
				label: '2020',
				options: {
					decimalPlaces: 0,
				},
			},
			{
				value: 656,
				label: '2019',
				options: {
					decimalPlaces: 1,
				},
			},
			{
				value: 615,
				label: '2018',
				options: {
					decimalPlaces: 1,
				},
			},
		],
	}),
}
</script>

<style scoped lang="scss">

</style>
